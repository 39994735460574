﻿import { getNumberAttribute } from "@shared/Utils";

interface ObserverOptions {
  root: Element;
  rootMargin: string;
  threshold: number | number[];
}

export class InterSectorEffects {
  public constructor() {
    this.init();
  }

  private observerOptions: ObserverOptions = {
    root: null,
    rootMargin: "0px",
    threshold: [0, 0.1],
  };

  init(): void {
    const effectContainers = document.querySelectorAll(".js-intersect-effect");

    const observer = new IntersectionObserver(this.observerCallBack(), this.observerOptions);

    effectContainers.forEach((item) => {
      this.addObserver(item, observer);
    });
  }

  addObserver(item: Element, observer: IntersectionObserver): void {
    observer.observe(item);
  }

  observerCallBack(): IntersectionObserverCallback {
    return (entries: Array<IntersectionObserverEntry>): void => {
      entries.forEach((entry) => {
        const box = entry.target;

        let ratioAttr = box.getAttribute("ratio");
        const wait = getNumberAttribute(box, "intersect-wait");

        if (!ratioAttr || ratioAttr === null) {
          ratioAttr = "0.01";
        }
        const ratio = +ratioAttr; //convert to number

        if (entry.intersectionRatio >= ratio) {
          if (wait > 0) {
            setTimeout(function () {
              box.classList.add("effects");
            }, wait);
          } else {
            box.classList.add("effects");
          }

          box.classList.remove("effects-out");
        } else if (entry.intersectionRatio < ratio && box.classList.contains("effects")) {
          box.classList.add("effects-out");
        } else if (entry.intersectionRatio === 0.0) {
          //box.classList.remove("effects"); //TODO; remove when done testing
        }
      });
    };
  }
}

export class OnLoadEffects {
  public constructor() {
    this.init();
  }
  init(): void {
    const effectContainers = document.querySelectorAll<HTMLElement>(".js-onload-effect");

    effectContainers.forEach((item) => {
      item.classList.add("effects");
    });
  }
}

document.addEventListener("DOMContentLoaded", (): void => {
  new InterSectorEffects();
  new OnLoadEffects();
});
